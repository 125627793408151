.tail-datetime-calendar {
    width: 545px;
    height: auto;
    margin: 15px auto;
    padding: 0;
    display: block;
    /*position: absolute;*/
    border-collapse: separate;
    background-color: #ffffff;
    box-shadow: 0 5px 10px 0 rgba(50, 66, 88, 0.25);
    -webkit-box-shadow: 0 5px 10px 0 rgba(50, 66, 88, 0.25);
}

.tail-datetime-calendar:after {
    clear: both;
    content: "";
    display: block;
    font-size: 0;
    visibility: hidden;
}

.tail-datetime-calendar.calendar-static {
    margin-left: auto;
    margin-right: auto;
}

.tail-datetime-calendar .calendar-controls {
    margin: 0;
    padding: 9px 16px 11px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-spacing: 0;
    border-collapse: separate;
    background-color: #da1919;
    border-radius: 4px;
    /*-webkit-border-radius: 3px 3px 0 0;*/
}

.tail-datetime-calendar .calendar-controls span {
    cursor: pointer;
    color: #fff;
    margin: 0;
    padding: 0;
    display: inline-block;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.09;
    letter-spacing: normal;
    text-align: center;
    text-transform: uppercase;
    /*text-align: center;*/
    /*line-height: 30px;*/
    text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.15);
    background-repeat: no-repeat;
    background-position: center center;
}

.tail-datetime-calendar .calendar-controls span:first-child,
.tail-datetime-calendar .calendar-controls span:last-child {
    width: 35px;
    padding: 0 0 5px 0;
    font-size: 22px;
}

.tail-datetime-calendar .calendar-controls span:hover {
    /*background-color: rgba(0, 0, 0, 0.15);*/
}

.tail-datetime-calendar .calendar-controls span.button-prev {
    width: 30px;
    height: 30px;
}

.tail-datetime-calendar .calendar-controls span.button-next {
    width: 30px;
    height: 30px;
}

.tail-datetime-calendar .calendar-controls span.button-check {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNMTIgNWwtOCA4LTQtNCAxLjUtMS41TDQgMTBsNi41LTYuNUwxMiA1eiIvPjwvc3ZnPg==");
}

.tail-datetime-calendar .calendar-controls span.button-close {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC\
    9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDEyIDE2Ij48cGF0aCBmaWxsPSIjZmZmZmZmIiBkP\
    SJNNy40OCA4bDMuNzUgMy43NS0xLjQ4IDEuNDhMNiA5LjQ4bC0zLjc1IDMuNzUtMS40OC0xLjQ4TDQuNTIgOCAuNzcgNC4y\
    NWwxLjQ4LTEuNDhMNiA2LjUybDMuNzUtMy43NSAxLjQ4IDEuNDhMNy40OCA4eiIvPjwvc3ZnPg==");
}

.tail-datetime-calendar .calendar-date {
    margin: 0;
    padding: 0;
    display: block;
}

.tail-datetime-calendar .calendar-date table {
    width: 100%;
    margin: 0;
    padding: 0;
    border-spacing: 0;
    border-collapse: collapse;
}

.tail-datetime-calendar .calendar-date table tr > *,
.tail-datetime-calendar .calendar-date table tbody tr > * {
    color: #0d0d0d;
    width: 78px;
    height: 78px;
    padding: 0;
    font-size: 10px;
    text-align: center;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 35px;
    border-bottom: 0;
    /*background-color: #223344;*/
}

.tail-datetime-calendar .calendar-date table tbody tr > * {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0d0d0d;
    cursor: pointer;
    position: relative;
    font-size: 26.2px;
    background-color: #ffffff;
    border-right: solid 1px rgba(0, 0, 0, 0.03);

}

.calendar-short-day {
    border-bottom: solid 2px rgba(0, 0, 0, 0.03) !important;
    height: 24px !important;
    line-height: 13px !important;
    vertical-align: middle;
}

.calendar-day > thead tr {
    background: #fff;
    height: 24px;
}

.calendar-days-month tr > tr > td {
    border-right: solid 2px rgba(0, 0, 0, 0.03);
}

.calendar-day {
    border-right: solid 1px rgba(0, 0, 0, 0.03);
    vertical-align: middle;
}

.tail-datetime-calendar .calendar-date table tbody tr > * > span {
    color: inherit;
    z-index: 1;
    position: relative;
}

.tail-datetime-calendar .calendar-date table tbody tr > *::after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 52px;
    height: 52px;
    content: "";
    display: block;
    position: absolute;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    transition: all .3s ease-in-out;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.today {
    color: #da1919;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.today:after {
    border-color: #da1919;
}

.tail-datetime-calendar .calendar-date table tbody tr > *:hover {
    color: #fff;
    transition: all .2s ease-in-out;
}

.tail-datetime-calendar .calendar-date table tbody tr > *:hover:after {
    background-color: #da1919;
    transition: all .2s ease-in-out;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.empty,
.tail-datetime-calendar .calendar-date table tbody tr > *.disable {
    color: #8899aa;
    cursor: not-allowed;
    background-color: #f9f9f9;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.empty:hover:after {
    content: "";
    display: none;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.disable:hover:after {
    content: "";
    display: block;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.current span {
    color: #da1919;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.current:after {
    border-color: #da1919;
}

/* Select A Month */
.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * {
    width: 81.66666666666667px;
    padding: 5px;
    line-height: 25px;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > * span {
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > *:hover span {
    border-color: #d0d0d0;
    box-shadow: 0 1px 0 0 #efefef;
    -webkit-box-shadow: 0 1px 0 0 #efefef;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > *:hover span {
    border-color: #da1919;
    box-shadow: 0 1px 0 0 #da1919;
    -webkit-box-shadow: 0 1px 0 0 #da1919;
    color: #da1919;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > *::after {
    display: none;
}

.tail-datetime-calendar .calendar-date table.calendar-month tbody tr > *::after span {
    border-color: #da1919;
    box-shadow: 0 1px 0 0 #da1919;
    -webkit-box-shadow: 0 1px 0 0 #da1919;
}


.tail-datetime-calendar .calendar-time {
    width: 100%;
    margin: 0;
    padding: 0;
    display: table;
    text-align: center;
    background-color: #ffffff;
    border-radius: 0 0 3px 3px;
    -webkit-border-radius: 0 0 3px 3px;
}

.tail-datetime-calendar .calendar-date + .calendar-time {
    border-top: 1px solid #d0d0d0;
    background-color: #f8f8f8;
}

.tail-datetime-calendar .calendar-time .calendar-field {
    width: 33.3333333333%;
    padding: 10px 0;
    display: table-cell;
    position: relative;
    text-align: center;
    vertical-align: top;
}

.tail-datetime-calendar .calendar-time .calendar-field:first-child {
    text-align: right;
}

.tail-datetime-calendar .calendar-time .calendar-field:first-child:after {
    top: 12px;
    right: -10px;
    content: ":";
    position: absolute;
}

.tail-datetime-calendar .calendar-time .calendar-field:last-child {
    text-align: left;
}

.tail-datetime-calendar .calendar-time .calendar-field:last-child:after {
    top: 12px;
    left: -10px;
    content: ":";
    position: absolute;
}

.tail-datetime-calendar .calendar-time .calendar-field input[type="number"] {
    width: 100%;
    max-width: 50px;
    margin: 0;
    padding: 3px 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    border-width: 1px;
    border-style: solid;
    border-color: #d0d0d0;
    background-color: #ffffff;
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.tail-datetime-calendar
.calendar-time
.calendar-field
input[type="number"]:hover {
    border-color: #a0a0a0;
}

.tail-datetime-calendar
.calendar-time
.calendar-field
input[type="number"]:focus {
    border-color: #da1919;
}

.tail-datetime-calendar .calendar-time .calendar-field label {
    color: #778899;
    margin: 5px 0 0 0;
    padding: 0;
    display: block;
    font-size: 12px;
    line-height: 12px;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.active > span {
    color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.current:hover span {
    color: #fff;
}

.tail-datetime-calendar .calendar-date table tbody tr > *.active:after {
    background-color: #da1919;
}

@media (max-width: 576px) {
    .tail-datetime-calendar {
        width: 100%;
    }

    .tail-datetime-calendar .calendar-date table tbody tr > *::after {
        width: 40px;
        height: 40px;
    }
}
