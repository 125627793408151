/*Fonts*/
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&display=swap');

/*Styles*/
/*Basic*/
* {
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body, html {
  width: 100%;
  color: #000;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #f7f8fc;
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  color: #0d0d0d;
  line-height: 15px;

  &.overlay {
    overflow: hidden;
  }
}

a {
  color: #0d0d0d;
}

input,
button {
  border: 0 none;
  background-color: transparent;
}

input:focus,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

@mixin flexMix($fdirection, $ahorizontal, $avertical) {
  display: flex;
  flex-direction: $fdirection;
  justify-content: $ahorizontal;
  align-items: $avertical;
}

$color-grey-1: #0d0d0d;
$color-grey-2: #bcc1d3;
$color-red-1: #f03337;
$color-red-2: #da1919;
$color-red-3: #e04545;

/*Forms*/
.form--default {

  .form {
    &--title {
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      color: #4a4a4a;
      text-transform: uppercase;
    }

    &--title2 {
      margin-bottom: 40px;
      opacity: 0.34;
      font-size: 16px;
      font-weight: 500;
      color: #000000;
      text-transform: uppercase;
    }

    &--row {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      &.custom-select {
        position: relative;

        select {
          display: none;
        }

        .select-selected {
          padding-right: 40px;
          background: url('../../admin/assets/img/svg/arrow-dropdown.svg') no-repeat 95% 50%;
          background-size: 14px 7px;

          &.select-arrow-active {
            background-image: url('../../admin/assets/img/svg/arrow-dropup.svg');
          }
        }

        .select-items {
          position: absolute;
          top: 101%;
          left: 0;
          right: 0;
          border-radius: 4px;
          border: solid 1px #dcdcdc;
          background-color: #ffffff;
          z-index: 2;

          &.select-hide {
            display: none;
          }

          div {
            padding: 8px 16px;
            font-size: 14px;
            border-bottom: solid 1px #dcdcdc;

            &:hover {
              background-color: #eee;
            }

            &:last-child {
              border: 0 none;
            }
          }
        }
      }

      &_file {
        margin-bottom: 24px;
        position: relative;
        overflow: hidden;

        .btn-filedownload {
          padding: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
          text-transform: capitalize;
        }

        .btn-fileupload {
          position: relative;
          padding: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
        }

        .btns {
          width: 100%;
          text-align: left;

          .icons {
            fill: $color-red-3;
          }
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }

      &_file2 {
        position: relative;
        overflow: hidden;

        .btn-fileupload2 {
          position: relative;
          padding: 0;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.5;
        }

        .btns {
          padding: 65px 0;
          width: 100%;
          border-radius: 4px;
          border: solid 1px #dcdcdc;

          .icons {
            fill: #dcdcdc;
          }
        }

        input {
          opacity: 0;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
        }

        &.fileuploaded {
          .btns {
            .icons {
              fill: $color-red-2;
            }
          }
        }
      }

      &_info {
        margin-bottom: 8px;
        font-size: 14px;
        line-height: 1.57;
      }

      &_submit .btns {
        padding: 8px 16px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        letter-spacing: 1px;
      }
    }

    &--label {
      display: block;
      margin-bottom: 4px;
      opacity: 0.54;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
      text-transform: uppercase;
    }

    &--input,
    &--select,
    &--textarea,
    &--custom .select-selected {
      display: block;
      padding: 8px 16px;
      width: 100%;
      font-size: 16px;
      color: #000000;
      line-height: 20px;
      border-radius: 4px;
      border: solid 1px #dcdcdc;
      background-color: transparent;
    }

    &--input {
      &::-webkit-input-placeholder {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.34);
      }

      &::-moz-placeholder {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.34);
      }

      &:-ms-input-placeholder {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.34);
      }

      &:-moz-placeholder {
        font-size: 16px;
        color: rgba(0, 0, 0, 0.34);
      }

      &.datepicker-here {
        background: url('../../admin/assets/img/svg/date.svg') no-repeat 95% 50%;
        background-size: 18px 20px;
      }

      &.timepicker-here {
        background: url('../../admin/assets/img/svg/time.svg') no-repeat 95% 50%;
        background-size: 20px;
      }
    }

    &--select {
      option {
        &:first-child,
        &:disabled {
          opacity: 0.34;
          font-size: 16px;
          color: #000000;
        }
      }
    }

    &--radio,
    &--checkbox {
      opacity: 0;
      height: 0;
      width: 0;
      display: inline-block;
      position: relative;
      user-select: none;

      &__label {
        position: relative;
        display: inline-block;
        padding-left: 30px;
        font-size: 14px;
        cursor: pointer;
        line-height: 20px;

        &:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          margin-top: -12px;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          background-size: 100%;
        }
      }
    }

    &--radio {
      &__label:before {
        background: url('../../assets/img/svg/radiobox.svg') no-repeat 50% 50%;
      }

      &:checked + .form--radio__label:before {
        background-image: url('../../assets/img/svg/radiobox_checked.svg');
      }
    }

    &--checkbox {
      &__label:before {
        background: url('../../assets/img/svg/checkbox.svg') no-repeat 50% 50%;
      }

      &:checked + .form--checkbox__label:before {
        background-image: url('../../assets/img/svg/checkbox_checked.svg');
      }
    }
  }

  .reset_btn {
    color: rgba(0, 0, 0, 0.34);

    &:hover {
      box-shadow: 0 8px 20px 0 #dfdfdf;
      background-color: #ffffff;
    }
  }

  .submit_btn {
    color: #fff;
    background-color: $color-red-3;
    box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);

    &:hover {
      box-shadow: unset;
    }
  }

  .delete_btn {
    color: $color-red-3;

    &:hover {
      color: #fff;
      box-shadow: 0 8px 20px 0 #e7b7a4;
      background-color: $color-red-3;
    }
  }
}

/*Forms*/

.site__width {
  margin: 0 auto;
  padding: 0 32px;
  max-width: 1280px;
}

/*Basic*/
/*Header*/
.header {
  &__main {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 6px 12px;
    background-color: #f7f8fc;
    z-index: 2;

    &--logo {
      float: left;
      margin-right: 45px;

      .logo:before {
        width: 209px;
        height: 65px;
      }
    }

    &--nav {
      float: left;
    }

    &--menu {
      padding-top: 20px;
      list-style: none;
    }

    &--item {
      display: inline-block;

      .btns {
        padding: 6px 12px;
        font-size: 14px;
        font-weight: 600;
        color: $color-grey-1;

        &:hover {
          color: $color-red-3;
        }
      }

      &.active {
        .btns {
          color: $color-red-3;
        }
      }
    }

    &--btns {
      float: right;
      padding-top: 20px;

      .btns {
        .icons {
          fill: $color-grey-2;
        }

        &:hover .icons {
          fill: $color-red-3;
        }
      }


    }
  }
}

/*Header*/

/*BreadCrumbs*/
.breadcrumbs {
  position: relative;
  margin: 0 0 24px 12px;
  padding-right: 44px;

  &--text {
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    text-transform: uppercase;

    a,
    span {
      font-family: Roboto;
      font-size: 12px;
      font-weight: 500;
      color: #e4e5e9;
      text-decoration: none;
      text-transform: uppercase;
    }

    a:last-child {
      color: rgba(0, 0, 0, 0.5);
    }

    span {
      padding: 0 5px;
    }
  }

  &--btns {
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -9px;

    .icons {
      width: 14px;
      height: 18px;
      fill: $color-red-3;
    }

    &:hover {
      .icons {
        fill: $color-red-3;
      }
    }
  }
}

/*BreadCrumbs*/

/*Content*/
.page_wrapper {
  position: relative;
  min-height: 100vh;
}

.content__main {
  position: relative;
  min-height: 100%;
}

/*HomePage*/
.signin {
  &__page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    background-color: #fff;

    &.content__main {
      @include flexMix(column, center, center);
    }
  }

  &__section {
    @include flexMix(row, center, center);

    &--left {
      margin-right: 20px;
      flex: 0 0 452px;
    }

    &--right {
      flex: 1 1 686px;
    }

    &--text {
      font-family: 'Nunito Sans', sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 1.31;
      letter-spacing: 0.24px;
      color: $color-red-3;
      text-align: center;
      text-transform: uppercase;
    }

    &--logo {
      margin-bottom: 40px;

      .logo:before {
        width: 452px;
        height: 139px;
      }
    }

    &--from {
      margin-top: 140px;
    }

    &--image {
      img {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &__form {
    &--row {
      margin-bottom: 24px;
    }

    &--input {
      padding: 16px;
      width: 100%;
      font-size: 16px;
      line-height: 1.5;
      text-align: center;
      border: 0 none;
      border-bottom: 1px solid rgba(41, 46, 58, 0.4);

      &.error {
        color: $color-red-3;
        border-color: $color-red-3;
      }
    }

    &--btns {
      padding-top: 16px;
      text-align: center;
    }

    &--submit {
      padding: 12px 48px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      letter-spacing: 1px;
      color: #ffffff;
      text-transform: uppercase;
      background-color: $color-red-3;
      border-radius: 2px;
      box-shadow: 0 8px 20px 0 rgba(218, 25, 25, 0.08);
    }
  }
}

/*HomePage*/

/*SectionContent*/
.section--content {
  padding: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

/*SectionContent*/

/*Tabs*/
/*Buttons*/
.tabs--btns {
  padding: 12px 0 0 0;

  li {
    position: relative;
    display: inline-block;
    margin: 0 16px;

    .btns {
      padding: 8px 16px;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.34);
      border-radius: 2px;

      a:hover {
        background-color: #9abee8;
      }
    }

    span.btns {
      cursor: default;
    }

    .notifications {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 16px;
      height: 16px;
      font-size: 11px;
      font-weight: bold;
      text-align: center;
      color: #ffffff;
      border-radius: 50%;
      box-shadow: 0 4px 10px 0 rgba(229, 104, 255, 0.5);
      background-color: #e568ff;
    }

    &.ads--add_btns {
      float: right;

      .btns {
        color: $color-red-3;
        text-transform: uppercase;

        .icons {
          margin-top: -3px;
          padding: 5px;
          fill: $color-red-3;
        }

        &:hover {
          color: #fff;

          .icons {
            fill: #fff;
          }
        }
      }
    }
  }

  .ui-state-active .btns,
  .btns:hover {
    color: #fff;
    box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);
    background-color: $color-red-3;
  }
}

/*Buttons*/
/*Header*/
.sticky-tabs-header .tabs--header {
  position: fixed;
  top: 0;
  right: 32px;
  left: 32px;
  z-index: 20;
}

.tabs--header {
  padding: 24px 0;
  transition: top ease 0.5s;
  background-color: #f8f8f8;

  /*Search*/
  &_search {
    float: left;
    min-height: 40px;

    &-show {
      float: left;
      margin: 8px 0 0 8px;
      fill: $color-red-3;

      &:hover {
        fill: $color-red-3;
      }
    }

    &-form {
      position: relative;
      float: left;
      display: none;
      width: 420px;
    }

    &-input {
      position: relative;
      padding: 12px 54px;
      width: 420px;
      height: 40px;
      font-size: 14px;
      line-height: 1.71;
      color: $color-red-3;
      background-color: transparent;
      border: 0 none;
      z-index: 1;
    }


    &-submit {
      position: absolute;
      top: 8px;
      left: 8px;
      fill: $color-red-3;
      cursor: pointer;
      background-color: transparent;
      border: none;
      z-index: 2;
    }

    &-clear {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0.34;
      z-index: 2;

      &:hover {
        opacity: 1;
        fill: $color-red-3;
      }
    }

    &-count {
      float: left;
      margin: 8px 0 8px 16px;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2;
      color: #4a4a4a;
    }
  }
}

.tabs--header_search-input,
.popup--content_input {
  border-bottom: 2px solid transparent;

  &:focus {
    outline: none;
    border-bottom: 2px solid $color-red-3;

    & + .tabs--header_search-submit {
      fill: $color-red-3;
    }
  }

  &::-webkit-input-placeholder {
    opacity: 0.34;
    color: #000000;
  }

  &::-moz-placeholder {
    opacity: 0.34;
    color: #000000;
  }

  &:-ms-input-placeholder {
    opacity: 0.34;
    color: #000000;
  }

  &::-moz-placeholder {
    opacity: 0.34;
    color: #000000;
  }
}

.show-search .tabs--header_search-form {
  display: block;
}

.show-search .tabs--header_search-show {
  display: none;
}

/*Search*/

/*Menu*/
.tabs--header_menu {
  float: right;
  list-style: none;

  li {
    float: left;
    padding: 0 8px;
    border-left: 1px solid #e2e4ec;

    &:first-child {
      border: 0 none;
    }
  }

  .btns {
    padding: 6px;
    width: 36px;
    height: 36px;
    font-size: 14px;
    font-weight: 500;
    color: $color-red-3;
    text-align: center;
    border-radius: 2px;

    .icons {
      fill: $color-red-3;
    }

    &:hover {
      color: #fff;
      box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);
      background-color: $color-red-3;

      .icons {
        fill: #fff;
      }
    }
  }

  .verify-all_btn {
    padding: 18px 6px;
  }
}

/*Menu*/
/*Header*/

/*Tables*/
.table--list {
  border-radius: 4px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;

  /*TablesHeader*/
  &_header {
    padding: 5px;
    background-color: #f8f8f8;
    border-bottom: 1px solid #dcdcdc;
    transition: top ease 0.5s;

    .filter-row {
      display: none;

      .table--list_cell, .rdt_TableCell {
        position: relative;
        color: rgba(255, 255, 255, 0.7);
      }

      .filter--form_select,
      .filter--form_input {
        margin: auto;
        padding: 3px;
        color: rgba(255, 255, 255, 0.7);
      }

      .filter--form_select {
        border: 0 none;
        background-color: transparent;
      }

      .filter--form_input {
        border: 1px solid rgba(255, 255, 255, 0.7);
        background-color: transparent;
      }

      .datepicker-here {
        display: none;
        margin-top: 2px;
        vertical-align: middle;
      }

      .datepicker_btn {
        vertical-align: middle;

        &.off {
          width: auto;
          height: auto;
          padding: 0;
          display: none;
          border-radius: 50%;
        }
      }

      &.show-datepicker {
        .datepicker_btn.on {
          display: none;
        }

        .datepicker_btn.off {
          display: inline-block;
        }

        .datepicker-here {
          display: inline-block;
          width: 120px;
        }
      }
    }

    .table--list_cell, .rdt_TableCell {
      padding: 8px 16px;
      text-align: center;

      &:first-child,
      &:first-child .btns {
        text-align: left;
      }

      &:last-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }

      .btns {
        width: 100%;
        height: 100%;

        .icons {
          opacity: 0.54;
        }
      }

      .btns:hover .icons {
        opacity: 1;
      }

      &.active {
        .btns {
          color: #000;

          .icons {
            opacity: 1;
          }
        }
      }
    }

    .cell--filter {
      text-align: right;

      .btns {
        width: auto;
        height: auto;

        &:hover {
          box-shadow: 0 8px 20px 0 #b8b8b8;
          background-color: #4d505b;

          .icons {
            fill: #fff;
          }
        }

        .icons {
          fill: $color-red-3;
          opacity: 1;
        }
      }
    }

    .btns {
      padding: 6px 8px;
      font-size: 12px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.7);
      line-height: 24px;
      text-transform: uppercase;

      &:hover {
        color: #000;
        background-color: rgba(0, 0, 0, 0.04);
      }
    }

    &-total {
      opacity: 0.5;
      font-size: 12px;
      font-weight: 500;
      color: #000000;
    }

    .cell--filter .icons.off,
    &.show-filter .cell--filter .icons.on {
      display: none;
    }

    &.show-filter {
      background-color: #4d505b;

      .table--list_cell, .rdt_TableCell {
        .btns {
          color: rgba(255, 255, 255, 0.7);

          .icons {
            fill: #fff;
          }

          &:hover {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.05);

            .icons {
              opacity: 1;
            }
          }
        }

        &.active {
          .btns {
            color: #fff;
            background-color: rgba(255, 255, 255, 0.05);
          }
        }

        .filter_btn:hover {
          box-shadow: 0 8px 20px 0 #aaaaaa;
          background-color: #f8f8f8;
          border-radius: 2px;

          .icons {
            fill: $color-red-3;
          }
        }
      }

      .filter-row {
        display: flex;
      }

      .cell--filter .icons.off {
        display: inline-block;
      }
    }
  }
  .rdt_TableHeadRow {
    background-color: #f8f8f8;
  }

  .rdt_TableCol:nth-child(7) {
      margin-left: 45px;
    padding-right: 22px;
    fill: #da1919;
    opacity: .9;
  }
  .rdt_TableCol:last-child {
      display: none;
  }

  .table-header-item {
    font-family: Roboto;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
  }

  /*TablesHeader*/

  &_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-flow: row wrap;
    // justify-content: space-between;

    &.noresults {
      flex-direction: column;
    }
  }

  &_cell {
    position: relative;
    /* border: 1px solid #eee; */
  }

  /*TablesBody*/
  &_body {
    padding: 5px;

    .table--list_row, .rdt_TableRow {
      min-height: 58px;
      border: 1px solid transparent;
      border-bottom-color: #dcdcdc;

      &:last-child {
        border-bottom-color: transparent;
      }

      &:hover {
        position: relative;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #dcdcdc;
        background-color: #ffffff;
        z-index: 20;

        .table--list_cell, .rdt_TableCell {
          font-weight: 500;
        }

        .cell--editor_menu {
          display: block;
        }
      }
    }

    .table--list_cell {
      min-height: 56px;

      &:not(:first-child) {
        text-align: center;
      }

      &:not(.cell--editor) {
        padding: 16px;
        line-height: 24px;
      }
    }

    .cell--editor_menu {
      display: none;
    }
  }

  /*TablesBody*/
}

/*TablesHeader*/
.sticky-table-header .table--list_header {
  position: fixed;
  top: 0;
  right: 32px;
  left: 32px;
  z-index: 30;
}

.sticky-table-header.sticky-tabs-header .table--list_header {
  top: 88px;
}

/*TablesHeader*/

/*TablesBody*/
/*CellEditor*/
.cell--editor_menu {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
  list-style: none;

  li {
    display: block;
    float: right;

    &:not(:first-child):after {
      content: '';
      display: inline-block;
      margin-right: 3px;
      width: 1px;
      height: 40px;
      background-color: #e2e4ec;
      vertical-align: middle;
    }
  }

  .btns {
    padding: 8px;
    fill: $color-red-3;

    &:hover {
      fill: #fff;
      background-color: $color-red-3;
    }
  }

  .delete_btn:hover {
    background-color: $color-red-3;
  }

  .decline_btn:hover {
    background-color: $color-red-3;
  }
}

/*CellEditor*/

/*Appointments*/
.appointments--tables {

  .cell_patient {
    flex: 0 1 25%;
  }

  .cell_type {
    flex: 0 1 20%;
  }

  .cell_date {
    flex: 0 1 15%;
  }

  .cell_time {
    flex: 0 1 10%;
  }

  .cell_registered {

    flex: 0 1 15%;
  }

  .cell--filter,
  .cell--editor {
    flex: 0 1 15%;
  }

  .cell--editor_menu {
    width: 120px;
  }
}

/*Appointments*/
/*Blog*/
.blog--tables {
  .cell_title {
    flex: 0 1 70%;
  }

  .cell_date {
    flex: 0 1 15%;
  }

  .cell--filter,
  .cell--editor {
    flex: 0 1 15%;
  }

  .cell--editor_menu {
    width: 120px;
  }
}

/*Blog*/
/*Noresults*/
.noresults {
  padding: 100px;

  &--count {
    font-size: 64px;
    font-weight: 100;
    color: $color-red-3;
    line-height: 1;
  }

  &--text {
    margin: 4px 0 8px 0;
    font-size: 24px;
    font-weight: 500;
    color: $color-red-3;
    line-height: 1;
    text-transform: uppercase;
  }

  &--info {
    margin-bottom: 32px;
    font-size: 16px;
    color: #4a4a4a;
    line-height: 1;
  }

  &--link_btn {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.34);
    text-transform: uppercase;
  }
}

/*Noresults*/
/*TablesBody*/
/*Tables*/
/*Tabs*/

/*AppointmentNewEdit*/
.appointment {
  &--new,
  &--edit {

    &_header {
      margin-bottom: 40px;

      &-text {
        float: left;
      }

      &-title.form--title {
        text-transform: capitalize;
      }

      &-btns {
        float: right;

        .btns {
          padding: 8px 16px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          line-height: 1.5;
          letter-spacing: 1px;
          box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);
          background-color: $color-red-3;
          border-radius: 2px;

          &:hover {
            box-shadow: unset;
          }
        }
      }
    }

    &_content {
      @include flexMix(row, space-between, start);
      flex-wrap: wrap;

      .form--column {
        width: 25%;

        &:nth-child(2) {
          margin: 0 18px;
          padding: 0 18px;
          width: calc(38% - 36px);
          border-right: 1px solid #dcdcdc;
          border-left: 1px solid #dcdcdc;
        }
      }
    }

    .form--row_submit {
      flex: 1 1 100%;
      margin-top: 24px;
      padding-top: 24px;
      text-align: center;
      border-top: 1px solid #eee;

      .btns {
        margin: 0 24px;
      }
    }
  }
}

/*AppointmentNewEdit*/
/*BlogNewEdit*/
.blog {
  &--new,
  &--edit {
    &_header {
      margin-bottom: 40px;

      &-title.form--title {
        text-transform: capitalize;
      }
    }

    &_content {
      @include flexMix(row, space-between, stretch);
      flex-wrap: wrap;
      min-height: 40vh;

      .form--column {
        width: 32%;

        &:nth-child(2) {
          margin-left: 18px;
          padding-left: 18px;
          width: calc(68% - 18px);
          border-left: 1px solid #dcdcdc;

          .form--row {
            height: 100%;
          }
        }
      }
    }

    .form--row {

      &.form--row_textarea {
        textarea {
          height: 100%;
        }
      }

      &_submit {
        flex: 1 1 100%;
        margin-top: 24px;
        padding-top: 24px;
        text-align: center;
        border-top: 1px solid #eee;

        .btns {
          margin: 0 24px;
        }
      }
    }
  }
}

/*BlogNewEdit*/
/*Availabilitys*/
.availabilitys {
  &_content {
    @include flexMix(column, space-between, center);

    .form--column {
      width: 49%;
    }
  }

  &-title {
    text-align: center;
  }

  &__main_select {
    margin: 20px auto 15px;
    max-width: 545px;
  }

  &__calendar {
    background-color: #f6f6f6;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #f6f6f6;

    &--month {
      position: relative;
      padding: 16px 14px 14px 14px;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      border-radius: 4px;
      background-color: $color-red-3;

      .btns {
        position: absolute;
        top: 50%;
        margin-top: -12px;
      }

      &_prev {
        left: 12px;
      }

      &_next {
        right: 12px;
      }
    }

    &--daysNames,
    &--week {
      @include flexMix(row, space-between, center);
      border-top: 1px solid rgba(151, 151, 151, 0.03);

      .availabilitys__calendar--day {
        flex: 0 0 14.25%;
        padding: 7px;
        text-align: center;
      }
    }

    &--daysNames {
      background-color: #fff;
      border-radius: 4px;

      .availabilitys__calendar--day {
        span {
          font-size: 10px;
          font-weight: 600;
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }

    &--day {
      background-color: #fff;

      &.closed,
      &.open,
      &.selected {
        cursor: pointer;
        border-left: 1px solid rgba(151, 151, 151, 0.03);

        span {
          display: block;
          margin: 0 auto;
          padding-top: 18px;
          width: 52px;
          height: 52px;
          font-size: 26.2px;
          text-align: center;
          border-radius: 50%;
        }

        &:first-child() {
          border-left: 0 none;
        }
      }

      &.closed {
        opacity: 0.3;
      }

      &.open:hover,
      &.selected {
        span {
          color: #fff;
          background-color: $color-red-3;
        }
      }
    }

    &--time {
      display: none;
      padding: 16px;

      &.show-time {
        display: block;
      }

      &.show-editor {
        display: block;

        .availabilitys__edit--block {
          display: block;
        }

        .availabilitys__time--block {
          display: none;
        }
      }
    }
  }

  &__time,
  &__edit {

    &--block {
      padding: 16px;
      background-color: #fff;
      border-radius: 5px;
    }

    &--title {
      margin-bottom: 12px;
      padding-top: 6px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      text-transform: uppercase;

      .btns {
        float: right;
        margin-top: -6px;

        .icons {
          fill: $color-red-3;
        }
      }
    }
  }

  &__time {
    &--title {
      display: flex;
      justify-content: space-around;

      p {
        margin-right: 40px;
        font-weight: 700;
      }
    }

    &--item {
      position: relative;
      padding: 16px 150px 16px 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.03);

      .btns.btn-i {
        position: absolute;
        top: 50%;
        right: 0px;
        margin-top: -18px;
        width: 136px;
        padding: 11px 8px;
        font-size: 12px;
      }
    }

    &--time {
      margin-bottom: 4px;
      font-size: 18px;
      font-weight: 700;
      white-space: nowrap;
      vertical-align: middle;

      span {
        display: none;
        font-size: 10px;
        font-weight: 700;
        vertical-align: middle;
      }
    }

    &--price {
      font-size: 14px;
      font-weight: 700;
      color: $color-red-3;
    }
  }

  &__edit {

    &--block {
      display: none;
    }

    &--title {
      .btns {
        float: right;
        padding: 6px 8px;
        font-size: 18px;
        font-weight: bold;
        color: $color-red-3;
        line-height: 1.33;
      }
    }

    &--item {
      margin-left: -4px;
      @include flexMix(row, space-between, center);
      position: relative;
      padding: 16px 50px 16px 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.03);

      &-column3 {
        margin-right: 4px;
        margin-left: 4px;

        &.time {
          flex: 0 1 25%;
        }

        &.period {
          flex: 0 1 50%;
        }
      }

      &-btns {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -12px;

        .btns {
          .icons {
            fill: $color-red-3;
          }
        }
      }

      &.add {
        border-bottom: 0 none;

        .btns {
          font-size: 14px;
          line-height: 1.43;
          color: $color-red-3;

          .icons {
            margin-right: 12px;
            stroke: $color-red-3;
          }
        }
      }
    }
  }
}

.admin_submit_row {
  padding-top: 10px;
}

.admin-attachment-icon{
  padding-bottom: 3px;
}

/*Availabilitys*/
/*Content*/

/*Footer*/
.footer {
  &__main {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 16px 32px;
    z-index: 0;
  }

  &--copyrights {
    float: left;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.4);
  }

  &--development {
    float: right;
    font-size: 10px;
    line-height: 1;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.3);
    text-transform: uppercase;
  }

  &--logo_devensis {
    display: inline-block;
    margin-left: 8px;
    width: 95px;
    height: 10px;
    background-image: url('../../assets/img/svg/logo.svg'); //devensis-logo.svg
    background-size: 95px 10px;
    vertical-align: middle;
  }
}

/*Footer*/

/*Popups*/
.popup {

  &_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 40;
  }

  &_block {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -100px 0 0 -240px;
    width: 480px;
    max-height: 250px;
    padding: 32px 64px;
    border-radius: 4px;
    box-shadow: 0 8px 20px 0 #8b8b8b;
    background-color: #ffffff;
    text-align: center;
    z-index: 44;
    text-transform: none;

    .form--row_submit .btns {
      margin: 0 12px;
    }
  }

  &--title {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.2;
    color: #4a4a4a;
  }

  &--content {
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;

    div {
      margin-top: 15px;
      font-size: 16px;
      color: #4a4a4a;
      line-height: 1.5;
    }

    &.form_search {
      position: relative;

      .icons {
        position: absolute;
        top: 6px;
        left: 8px;
        fill: #17e;
      }

      .popup--content_input {
        padding: 6px 12px 6px 40px;
      }
    }

    &_input {
      padding: 6px 12px;
      width: 100%;
      font-size: 14px;
      line-height: 1.71;
      color: $color-red-3;
    }

    &.warning {
      color: $color-red-3;
    }
  }

  &--main {
    &.show .popup_overlay,
    &.appointmentdelete .popup--appointmentdelete,
    &.blogdelete .popup--blogdelete {
      display: block;
    }
  }
}

/*Popups*/

/*Icons*/
.icon {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
  }

  &.i24x24:before {
    width: 24px;
    height: 24px;
  }

  &.logo:before {
    background-image: url('../../assets/img/svg/logo.svg');
    background-size: 100%;
  }

  &.i-arrow-right_2:before {
    background-image: url('../img/svg/icon-arrow-right_2.svg');
    background-size: 75% 62.5%;
  }

  &.i-arrow-left_2:before {
    background-image: url('../img/svg/icon-arrow-left_2.svg');
    background-size: 75% 62.5%;
  }
}

.icons {
  display: inline-block;
  vertical-align: middle;

  &12x12 {
    width: 12px;
    height: 12px;
  }

  &24x24 {
    width: 24px;
    height: 24px;
  }

  &40x40 {
    width: 40px;
    height: 40px;
  }

  &56x56 {
    width: 56px;
    height: 56px;
  }
}

/*Icons*/

/*Buttons*/
.btns {
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;

  &.btn-i {
    display: inline-block;
    vertical-align: middle;
  }

  &.btn-b {
    display: block;
  }

  &.btn-red_b,
  &.btn-grey_b,
  &.btn-white_b {
    padding: 12px 24px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border-radius: 21px;
  }

  &.btn-red_b {
    color: #fff;
    background-color: $color-red-3;
    border: 1px solid $color-red-3;
    box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);

    &:hover {
      box-shadow: unset;
    }
  }

  &.btn-white_b {
    color: $color-red-3;
    background-color: #fff;
    border: 1px solid $color-red-3;

    &:hover {
      color: #fff;
      background-color: $color-red-3;
    }
  }

  &.btn-grey_b {
    color: #fff;
    background-color: #d0d0d0;
    border: 1px solid #d0d0d0;
  }
}

/*Buttons*/

/*Common*/
.hidden {
  display: none;
}

.site__width::after,
.clearfix::after {
  content: "";
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  visibility: hidden;
}

/*Common*/


/*Fonts*/
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700&display=swap');
/**Fonts*/

/*Variables*/
$color-black: #000;
$color-white: #fff;
$color-grey-1: #0d0d0d;
$color-grey-2: #353535;
$color-grey-3: #5d5d5d;
$color-grey-4: #656565;
$color-grey-5: #7f8fa4;
$color-red-1: #da1919;
$color-red-2: #E04545;
/**Variables*/

/*Basic*/
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  height: 100%;
}

body, html {
  width: 100%;
}

.loader {
  margin: 50% auto;
  text-align: center;
}

.lowerCase {
  text-transform: lowercase;
}

.disabled {
  cursor: not-allowed !important;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100%;
  background-color: #fff;
  font-size: 16px;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 400;
  color: $color-grey-1;
  line-height: 1;

  &.overflow {
    overflow: hidden;
  }

  &.overlay {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 9;
    }
  }
}

input,
textarea,
button {
  border: 0 none;
  background-color: transparent;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
  line-height: 1;
  font-size: inherit;
}

h1, h2, h3 {
  font-weight: 400;
}

p,
p * {
  font-size: inherit;
  color: inherit;
  line-height: inherit;
}

/*Forms*/
.form__default, .form--default {
  .admin-form--input {
    height: 48px;
    border: solid 1px #dcdcdc !important;
  }

  .admin-calendar-icon {
    position: absolute;
    right: 0.5vw;
    top: 0.7vw;
  }


  .form {
    &__row, {
      margin-bottom: 26px;

      &--btns {
        text-align: center;
      }
    }

    &--label {
      font-family: Roboto;
      opacity: 0.34;
      font-size: 12px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #000000;
    }

    &__input,
    &__textarea {
      padding: 16px;
      width: 100%;
      border: 1px solid rgba(13, 13, 13, 0.64);
      border-radius: 5px;

      &::-webkit-input-placeholder {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.13px;
        color: rgba(13, 13, 13, 0.42);
      }

      &::-moz-placeholder {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.13px;
        color: rgba(13, 13, 13, 0.42);

      }

      &:-ms-input-placeholder {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.13px;
        color: rgba(13, 13, 13, 0.42);

      }

      &:-moz-placeholder {
        font-size: 14px;
        line-height: 1.71;
        letter-spacing: 0.13px;
        color: rgba(13, 13, 13, 0.42);
      }

      &__date-picker-input {
        width: 100%;
      }
    }

    &__input {
      line-height: 2;
    }

    &__textarea {
      min-height: 144px;
    }

    &__radio,
    &__checkbox {
      opacity: 0;
      height: 0;
      width: 0;
      display: inline-block;
      position: relative;
      user-select: none;

      &--label {
        position: relative;
        display: inline-block;
        padding-left: 30px;
        font-size: 14px;
        cursor: pointer;
        line-height: 20px;

        &:before {
          position: absolute;
          content: '';
          top: 50%;
          left: 0;
          margin-top: -12px;
          width: 20px;
          height: 20px;
          vertical-align: middle;
          background-size: 100%;
        }
      }
    }

    &__radio {
      &--label:before {
        background: url('../img/svg/radiobox.svg') no-repeat 50% 50%;
      }

      &:checked + .form__radio--label:before {
        background-image: url('../img/svg/radiobox_checked.svg');
      }
    }

    &__checkbox {
      &--label:before {
        background: url('../img/svg/checkbox.svg') no-repeat 50% 50%;
      }

      &:checked + .form__checkbox--label:before {
        background-image: url('../img/svg/checkbox_checked.svg');
      }
    }

    &__file {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      cursor: pointer;

      &-btn {
        position: relative;
        padding: 8px 16px;
        font-size: 14px;
        font-size: 14px;
        font-weight: 600;
        color: $color-red-1;
        line-height: 1.5;
        text-transform: uppercase;
        background-image: url('../img/svg/icon-attachment.svg');
        background-size: 11px 22px;
        background-position: 0 50%;
        background-repeat: no-repeat;
      }

      &-uploaded {
        padding: 8px 25px;
        background-image: url('../img/svg/icon-ok.svg');
        background-size: 17px 17px;
        cursor: context-menu;
        color: inherit;
      }
    }
  }
}

/**Forms*/

/*Mixins*/
@mixin flex_justify_align($direction, $content, $items) {
  display: flex;
  flex-direction: $direction;
  justify-content: $content;
  align-items: $items;
}

/**Mixins*/
.desktop {
  display: none;
}

.site__width {
  position: relative;
  margin: auto;
  padding: 0 16px;
  max-width: 1440px;
}

/**Basic*/
/*Header*/
.header {
  &__main {
    position: fixed;
    padding: 14px 8px;
    top: 0;
    right: 0;
    left: 0;
    background-color: $color-white;
    z-index: 10;

    &.show-menu {
      .header__menu {
        right: 0;
      }
    }
  }

  &__logo {
    float: left;

    .logo:before {
      width: 104px;
      height: 32px;
    }
  }

  &__nav {
    float: right;
    padding-top: 4px;
  }

  &__menu {
    position: fixed;
    top: 60px;
    right: -320px;
    bottom: 0;
    padding: 42px 30px;
    width: 320px;
    text-align: center;
    background-color: $color-white;
    transition: right 0.5s ease;
    z-index: 11;
    overflow-y: scroll;

    &--list {
      list-style-type: none;

      & > li {
        margin-bottom: 12px;

        &:hover > a,
        &:hover > span,
        &.active > a,
        &.active > span {
          color: $color-red-1;
        }

        & > span,
        & > a {
          display: block;
          padding: 4px;
          font-size: 16px;
          font-weight: 600;
          text-decoration: none;
          text-transform: uppercase;

          & + ul {
            padding-top: 6px;
            list-style-type: none;

            & > li {
              margin-bottom: 6px;

              &:hover > a,
              &:hover > span,
              &.active > a,
              &.active > span {
                color: $color-red-1;
              }

              & > a {
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    &--btns {
      .btns:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

/**Header*/
/*Content*/
.section,
.page {
  &__title {
    padding-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    color: $color-red-1;
    text-align: center;
    text-transform: uppercase;

    &:after {
      content: '';
      margin: 4px auto;
      display: block;
      width: 50px;
      height: 1px;
      background-color: rgba(218, 25, 25, 0.24);
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    text-align: center;

    p {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.page {
  padding-top: 32px;
  padding-bottom: 200px;

  &.contactus__page {
    padding-bottom: 8px;
  }

  &__content {
    h2 {
      margin-bottom: 24px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.33;
      color: $color-grey-2;
      text-transform: uppercase;
    }

    ul {
      list-style-type: none;

      li {
        position: relative;
        margin-bottom: 8px;
        padding-left: 13px;
        color: $color-grey-1;

        &:before {
          position: absolute;
          top: 5px;
          left: 0;
          content: '';
          width: 5px;
          height: 5px;
          background-color: $color-red-1;
          border-radius: 5px;
        }
      }
    }
  }

  &__video {
    margin-bottom: 16px;
  }

  &__image {
    margin: 0 -16px 16px -16px;
    font-size: 0;
    line-height: 0;
    text-align: center;

    img {
      /*box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.26);*/
    }
  }

  &__text p {
    margin-bottom: 12px;

    small,
    small * {
      font-size: 14px;

      strong {
        font-weight: 700;
      }
    }
  }
}

.video__block {
  text-align: center;

  .btn-video {
    position: relative;
    font-size: 0;
    box-shadow: 0 1px 14px 0 rgba(0, 0, 0, 0.26);
    border-radius: 4px;
    overflow: hidden;

    img {
      width: 100%;
      height: auto;
      max-width: 100%;
    }

    &:hover {
      box-shadow: unset;
    }

    .i-play {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -28px 0 0 -28px;
    }
  }
}

/*Sections*/
/*Hero*/
.hero {

  &__section {
    padding-bottom: 25px;
    text-align: center;
    background-image: url('../img/hero_bg_m.png');
    background-image: -webkit-image-set(url('../img/hero_bg_m.png') 1x, url('../img/hero_bg_m@2x.png') 2x, url('../img/hero_bg_m@3x.png') 3x);
    background-position: 0 100%;
    background-repeat: no-repeat;
  }

  &__image {
    margin-bottom: 16px;
    text-align: center;
  }

  &__info {
    text-align: center;

    &--title {
      font-size: 24px;
      font-weight: 600;
      color: $color-red-2;
      line-height: 1;
      letter-spacing: 0.18px;
      text-transform: uppercase;
    }

    &--logo {
      margin-bottom: 8px;

      .logo:before {
        width: 288px;
        height: 89px;
      }
    }

    &--text {
      margin: 0 auto 24px auto;
      max-width: 500px;

      p {
        line-height: 1.5;
      }
    }
  }
}

/**Hero*/
/*Aboutus*/
.aboutus {
  &__section {
    padding: 32px 0;
    background-color: #fefefe;
    text-align: center;

    &--link {
      font-size: 12px;
      font-weight: 700;
      color: $color-red-1;
      text-transform: uppercase;
    }

    &--list {
      padding: 32px 0 64px 0;
    }

    &--item:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

/**Aboutus*/
/*Appointment*/
.appointment {
  &__section {
    // padding: 64px 0;
    padding-bottom: 250px;
    text-align: center;

    &--title {
      margin: 0 auto 24px auto;
      max-width: 400px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.33;
      color: $color-red-1;
    }
  }
}

/**Appointment*/
/**Sections*/
/*Pages*/
/*FAQs*/
.faqs {
  &__page {
    .page__title {
      text-transform: none;
    }
  }

  &__list {

    &--item {
      padding: 16px 0 24px 0;
      border-top: 1px solid rgba(13, 13, 13, 0.02);

      &.show-answer {
        .faqs__list--question p {
          color: $color-red-1;
        }

        .faqs__list--answer {
          display: block;
          break-word: break-word;
        }
      }
    }

    &--question {
      position: relative;
      margin-bottom: 10px;
      padding-right: 40px;

      p {
        font-weight: 600;
        line-height: 1.38;
      }

      .btn-show {
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -12px;
      }
    }

    &--answer {
      display: none;

      p {
        line-height: 1.5;
      }
    }
  }
}

/**FAQs*/
/*ContactUs*/
.contactus {
  &__form {
    margin-bottom: 42px;
    .agreement-text {
      line-height: 1.4;
      margin-bottom: 10px;
      font-size: 16px;
      font-family: 'Nunito Sans';
    }

    .form__input--textarea {
      min-height: 150px;
    }
  }

  &__map {
    margin: 0 -16px 24px -16px;
    height: 385px;

    iframe {
      height: 100%;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;

    &--address {

      .direction_wrapper {
        display: flex;

        a {
          height: 32px;
          margin-right: 10px;
        }

        p {
          text-transform: uppercase;
        }
      }

    }

    .our-info_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      order: 2;
    }

    .footer__copyrights {
      display: none;
      flex-direction: column;
    }

    ul {
      list-style: none;

      li {
        margin-bottom: 16px;
        padding-left: 0;

        &:before {
          display: none;
        }

        & > a {
          font-size: 14px;

          .icons {
            margin-right: 16px;
          }
        }
      }
    }

    //&--address {
    //	margin-bottom: 16px;
    //}

    &--text {
      position: relative;
      margin-bottom: 30px;
      padding-left: 48px;
      min-height: 32px;

      p {
        font-size: 14px;
      }

      .i-geo_rnd {
        //position: absolute;
        //top: 50%;
        //left: 0;
        margin-top: -16px;
      }
    }

    &--title {
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }

    &--social {
      text-align: center;

      ul {
        li {
          display: inline-block;
          margin: 0 5px;
        }
      }
    }
  }
}

/*ContactUs*/
/*AboutUs*/
.aboutus {
  &__page {
    margin: 0 auto;
    max-width: 1000px;

    .page__image {
      margin-bottom: 32px;
      text-align: center;

      img {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }

    .page__content {
      h2 {
        padding-top: 32px;
        color: $color-red-1;
      }

      ul {
        padding-bottom: 50px;
      }

      .logo {
        margin-bottom: 8px;

        .icons:before {
          width: 288px;
          height: 89px;
        }
      }

      .small__text {
        margin-bottom: 32px;
        font-size: 14px;
        line-height: 1.7;
      }
    }
  }
}

/**AboutUs*/
/*Advisors*/
.advisors,
.team {
  &__list {
    &--item {
      margin: 0 auto 10px auto;
      max-width: 315px;
      padding: 10px 0;
      text-align: center;
    }

    &--image {
      margin-bottom: 10px;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
        min-width: 100%;
      }

      &:hover {
        box-shadow: 0 0 15px 0 rgba(117, 117, 117, 0.5);
      }
    }

    &--title {
      margin-bottom: 4px;

      a {
        font-size: 18px;
        font-weight: 700;
        color: $color-grey-1;
        line-height: 1.56;
        text-decoration: none;
      }
    }

    &--subtitle {
      margin: 0 auto;
      max-width: 220px;
      font-size: 16px;
      color: $color-grey-3;
      line-height: 1.5;
    }

    &--link {
      visibility: hidden;

      a {
        font-size: 14px;
        font-weight: 700;
        color: $color-red-1;
        text-transform: uppercase;
      }
    }
  }
}

/**Advisors*/
/*Staff*/
.staff {

  &__page {
    padding-bottom: 100px;
    text-align: center;
  }

  &__image {
    margin: 0 auto 26px;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    overflow: hidden;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.33;
    color: $color-red-1;
  }

  &__subtitle {
    margin-bottom: 24px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    letter-spacing: 0.78px;
    color: $color-black;
  }

  &__content {
    margin: 0 auto 32px;
    max-width: 660px;
    line-height: 2;

    p {
      margin-bottom: 24px;
      line-height: 1.5;

      &:last-child {
        margin-bottom: 0;
      }
      &:nth-child(3) {
        margin-bottom: 0;
      }
    }
  }

  &__link {
    padding-bottom: 120px;

    a {
      font-size: 14px;
      font-weight: 700;
      color: $color-red-1;
      text-transform: uppercase;
    }
  }
}

/**Staff*/
/*FormsListLinks*/
.formsListLinks {
  &__page {
    position: relative;
    padding-bottom: 250px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      z-index: 1;
    }

    &:before {
      left: 0;
      width: 161px;
      height: 160px;
      background-image: url('../img/forms_listlinks_bg_left_m.jpg');
      background-image: -webkit-image-set(url('../img/forms_listlinks_bg_left_m.jpg') 1x, url('../img/forms_listlinks_bg_left_m@2x.jpg') 2x, url('../img/forms_listlinks_bg_left_m@3x.jpg') 3x);
      background-position: 0 100%;
      background-repeat: no-repeat;
    }

    &:after {
      right: 0;
      width: 180px;
      height: 213px;
      background-image: url('../img/forms_listlinks_bg_right_m.jpg');
      background-image: -webkit-image-set(url('../img/forms_listlinks_bg_right_m.jpg') 1x, url('../img/forms_listlinks_bg_right_m@2x.jpg') 2x, url('../img/forms_listlinks_bg_right_m@3x.jpg') 3x);
      background-position: 0 100%;
      background-repeat: no-repeat;
    }

    * {
      position: relative;
      z-index: 2;
    }
  }

  &__list {

    &--item {
      text-align: center;
      margin-bottom: 24px;
    }
  }
}

/**FormsListLinks*/
/*Allergy*/
.allergy {
  &Types {
    &__page {
      padding-bottom: 220px;
    }

    &__nav {
      margin-bottom: 20px;

      &--list {
        list-style: none;
        text-align: center;
      }

      &--item {
        display: inline-block;
        width: 120px;

        &:nth-child(1) {
          width: 190px;
        }
        &:nth-child(2) {
          width: 170px;
        }

        .btns {
          position: relative;
          padding-bottom: 20px;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          color: $color-grey-1;
          text-transform: uppercase;
          opacity: 0.64;
        }

        .icon {
          margin: 0 auto 16px;
          stroke: $color-grey-4;
        }

        .btns.active:after,
        &.slick-current .btns:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          margin-left: -5px;
          width: 10px;
          height: 7px;
          background-image: url('../img/svg/icon-arrow_down_red.svg');
          background-size: 100%;
        }

        .btns.active,
        &.slick-current .btns,
        &:hover .btns {
          color: $color-red-1;
          opacity: 1;

          .icon {
            stroke: $color-red-1;
          }
        }
      }
    }

    &__info {
      margin: 0 auto;
      max-width: 650px;

      h2 {
        margin-bottom: 26px;
        font-size: 24px;
        font-weight: 300;
        line-height: 1.33;
        color: $color-red-1;
      }

      h3 {
        margin-bottom: 18px;
        font-size: 16px;
        font-weight: bold;
        text-transform: uppercase;
      }

      p {
        margin-bottom: 10px;
        line-height: normal;
      }

      ul {
        margin-bottom: 32px;
        list-style-type: none;

        li {
          position: relative;
          margin-bottom: 10px;
          padding-left: 12px;
          font-size: 14px;
          line-height: 1.5;

          &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 8px;
            width: 5px;
            height: 4px;
            background-color: $color-red-1;
            border-radius: 4px;
          }
        }
      }
      .sub-list {
        margin-left: 25px;
        li {
          margin-bottom: 3px;
        }
        font-size: 12px;
      }
    }
  }

  &Symptoms {
    &__page {
      padding-bottom: 230px;

      h6 {
        margin-bottom: 8px;
        font-size: 14px;
        color: $color-red-1;
        text-transform: uppercase;
      }

      .recommendation-link a {
        text-decoration: underline;
        color: blue;
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  &Test {
    &__page {
      .page__text {
        text-align: center;
      }
    }
  }

  &Resources {
    &__list {

      &--item {
        margin: 0 auto 32px;
        max-width: 315px;
        text-align: center;
      }

      &--image {
        @include flex_justify_align(column, center, center);
        margin-bottom: 10px;
        min-height: 200px;
      }

      &--title {
        margin-bottom: 4px;
        font-size: 16px;
        color: $color-grey-3;
        line-height: 1.5;
      }

      &--info {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 1.38;
      }

      &--link {
        visibility: hidden;
        font-size: 14px;
        font-weight: 700;
        color: $color-red-1;
        text-transform: uppercase;
      }
    }
  }
}

/**Allergy*/
/*Articles*/
.articles {
  &__list {
    padding-top: 32px;

    &--item {
      margin-bottom: 32px;
    }

    &--date {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      color: $color-black;
    }

    &--title {
      margin-bottom: 8px;

      a {
        font-size: 20px;
        line-height: 1.3;
        color: #0d0d0d;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    &--text {
      margin-bottom: 10px;

      p {
        font-size: 14px;
        line-height: 1.43;
        color: $color-grey-3;
      }
    }

    &--image {
      margin: 0 -16px;

      img {
        width: 100%;
        height: auto;
        max-width: 100%;
      }
    }
  }

  &More {

    &__section {
      margin: 0 auto;
      max-width: 1010px;
    }

    &__sectionTitle {
      margin-bottom: 24px;
      padding-bottom: 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.56;
      letter-spacing: 1px;
      color: $color-red-1;
      border-bottom: 1px solid $color-red-1;
    }

    &__list {

      &--item {
        margin: 0 auto 32px auto;
        max-width: 320px;
      }

      &--image {
        margin: 0 -16px 12px -16px;

        img {
          width: 100%;
          height: auto;
          max-width: 100%;
        }
      }

      &--title {
        margin-bottom: 4px;

        a {
          text-decoration: none;
          line-height: 1.5;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &--date {
        font-size: 12px;
        font-weight: 700;
        line-height: 1.67;
        color: $color-grey-2;
      }
    }
  }
}

.article {
  &Full {
    &__page {
      .page__title {
        margin: 0 auto 4px auto;
        max-width: 600px;
        padding-bottom: 0;
        text-transform: capitalize;

        &:after {
          display: none;
        }
      }
    }
  }

  &__content {
    margin: 0 auto;
    padding-bottom: 32px;
    max-width: 660px;

    &--date {
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 700;
      line-height: 1.71;
      letter-spacing: 0.78px;
      color: $color-black;
      text-align: center;
    }

    p {
      margin-bottom: 24px;
      line-height: 1.5;
    }

    &--image {
      margin: 0 -16px 24px -16px;

      img {
        height: auto;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  &__share {
    &--list {
      list-style: none;
    }

    &--item {
      display: inline-block;
      margin-right: 8px;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }
  }
}

/**Articles*/
/*Booking*/
.booking {
  &__page {
    padding-bottom: 350px;
  }

  &__block {
    margin: 0 -13px;
    max-width: 545px;
    /*overflow: hidden;*/

    &.child {
      .booking__selectAge--note,
      .booking__formHeader--title,
      .booking__confirmHeader--time {

        .child {
          display: inline-block;
        }

        &.child {
          display: block;
        }
      }
    }

    &.adult {
      .booking__selectAge--note,
      .booking__formHeader--title,
      .booking__confirmHeader--time {
        .adult {
          display: inline-block;
        }

        &.adult {
          display: block;
        }
      }

      .form__row.child {
        display: none;
      }
    }
    .booking__confirmHeader--location-info {
      margin-top: 20px;

      & h5 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 2px;
      }
      & p {
        font-size: 12px;
      }
    }
  }

  &__selectAge {
    margin-bottom: 24px;
    padding: 0 12px;

    .custom-select {
      position: relative;
      margin-bottom: 24px;

      select {
        display: none;
      }

      .select-selected {
        margin-bottom: 18px;
        padding: 6px 10px;
        min-height: 48px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.29;
        letter-spacing: 0.13px;
        border: solid 1px #0d0d0d;
        border-radius: 4px;
      }

      .select-items {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: $color-white;
        border: 1px solid #eee;
        z-index: 2;

        &.select-hide {
          display: none;
        }

        & div {
          padding: 6px 10px;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.29;
          letter-spacing: 0.13px;

          &:hover {
            background-color: #eee;
          }
        }
      }
    }

    &--note {
      font-size: 11px;
      line-height: 1.64;

      strong {
        font-size: inherit;
        color: $color-red-1;
      }
    }
  }

  &__calendar {
    background-color: #f6f6f6;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 #f6f6f6;

    &--month {
      position: relative;
      padding: 16px 14px 14px 14px;
      font-size: 14px;
      font-weight: bold;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      border-radius: 4px;
      background-color: #e04545;

      .btns {
        position: absolute;
        top: 50%;
        margin-top: -12px;
      }

      &_prev {
        left: 12px;
      }

      &_next {
        right: 12px;
      }
    }

    &--daysNames,
    &--week {
      @include flex_justify_align(row, space-between, center);
      border-top: 1px solid rgba(151, 151, 151, 0.03);

      .booking__calendar--day {
        flex: 0 0 14%;
        padding: 7px;
        text-align: center;
      }
    }

    &--daysNames {
      background-color: $color-white;
      border-radius: 4px;

      .booking__calendar--day {
        span {
          font-size: 10px;
          font-weight: 600;
          line-height: 1;
          text-transform: uppercase;
        }
      }
    }

    &--day {
      background-color: $color-white;

      &.closed,
      &.open,
      &.selected {
        cursor: pointer;
        border-left: 1px solid rgba(151, 151, 151, 0.03);

        span {
          display: block;
          margin: 0 auto;
          padding-top: 8px;
          width: 30px;
          height: 30px;
          text-align: center;
          font-size: 15.2px;
          border-radius: 50%;
        }

        &:first-child() {
          border-left: 0 none;
        }
      }

      &.closed {
        opacity: 0.3;
      }

      &.open:hover,
      &.selected {
        span {
          color: $color-white;
          background-color: $color-red-1;
        }
      }
    }

    &--time {
      display: none;
      padding: 16px 13px;

      &.show-time {
        display: block;
      }
    }
  }

  &__time {

    &--block {
      padding: 16px 8px;
      background-color: $color-white;
      border-radius: 5px;
    }

    &--title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.13;
      text-align: center;
      color: $color-red-1;
    }

    &--item {
      position: relative;
      padding: 16px 150px 16px 0;
      border-bottom: solid 1px rgba(0, 0, 0, 0.03);

      .btns.btn-i {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -18px;
        width: 136px;
        padding: 11px 8px;
        font-size: 12px;
      }
    }

    &--time {
      margin-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      vertical-align: middle;

      span {
        display: none;
        font-size: 10px;
        font-weight: 700;
        vertical-align: middle;
      }
    }

    &--price {
      font-size: 14px;
      font-weight: 700;
      color: $color-red-1;
    }
  }

  &__form,
  &__confirm {
    margin: 0 -3px;
    padding: 16px;
    background-color: #f6f6f6;

    box-shadow: 0 2px 12px 0 #f6f6f6;

    p {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 1.57;
      text-align: center;
    }

    &--cencel {
      position: relative;
      margin: 0 -16px 16px -16px;
      padding: 16px 14px 14px 14px;
      font-size: 14px;
      font-weight: bold;
      color: $color-white;
      text-align: center;
      text-transform: uppercase;
      border-radius: 4px;
      background-color: #e04545;

      .btns {
        position: absolute;
        top: 50%;
        margin-top: -12px;
        right: 14px;
      }
    }

    &--bg {
      padding: 16px 8px;
      background-color: $color-white;
      border-radius: 5px;
    }

    &Header {
      margin-bottom: 16px;
      padding-bottom: 16px;
      text-align: center;
      border-bottom: solid 1px rgba(0, 0, 0, 0.03);

      &--title {
        display: none;
        margin-bottom: 8px;
        font-size: 18px;
        font-weight: 600;
        color: $color-red-1;
        line-height: 1;
        text-transform: uppercase;
      }

      &--time {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
      }

      &--price {
        font-size: 18px;
        font-weight: 600;
        color: $color-red-1;
      }
    }
  }

  &__form {
    .form {
      &__default {
        p {
          margin-bottom: 24px;
        }
      }

      &__row {
        position: relative;

        &.calendar {
          .i-calendar {
            position: absolute;
            top: 50%;
            margin-top: -12px;
            right: 12px;
            z-index: 1;
          }

          input {
            position: relative;
          }
        }

        &.referal {
          p {
            font-size: 16px;
            line-height: 1.38;
            text-align: left;
          }

          label {
            display: inline-block;
            margin-right: 18px;
            vertical-align: middle;
          }
        }

        &.attachment p {
          text-align: left;
        }

        &--btns {
          .btns {
            padding: 12px;
            font-size: 14px;

            &.btn-white_b {
              padding: 14px 12px;
              border: 1px solid $color-white;
            }
          }
        }
      }
    }

    &Footer {

      h5 {
        font-size: 14px;
        font-weight: 700;
        line-height: 1.57;
        text-transform: uppercase;
      }

      h6 {
        margin-bottom: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.57;
      }

      p {
        text-align: left;
      }
    }
  }

  &__confirm {

    &Header {
      &--title {
        display: block;
        text-transform: capitalize;
      }

      &--time {
        span {
          display: none;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.14;
        }
      }
    }

    &Body {
      &--row {
        margin-bottom: 24px;
        text-align: center;
      }

      &--title {
        margin-bottom: 10px;
        font-size: 12px;
        line-height: 1.33;
      }

      &--data {
        font-size: 18px;
        font-weight: 600;
        line-height: 1;
        color: $color-red-1;
      }
    }

    &Footer {
      padding-top: 16px;
      text-align: center;
      border-top: solid 1px rgba(0, 0, 0, 0.03);

      p {
        opacity: 0.8;
        font-size: 14px;
        line-height: 1.29;
      }

      .btns {
        padding: 12px;
        font-size: 14px;

        &.btn-white_b {
          padding: 14px 12px;
          border: 1px solid $color-white;
        }
      }
    }
  }
}

/**Booking*/
/**Pages*/
/*Pagination*/
.pagination {
  margin-bottom: 50px;
  justify-content: center;

  &__list {
    list-style: none;
    text-align: center;

    &--item {
      display: inline-block;
      margin: 6px;

      a,
      span {
        display: block;
        padding-top: 3px;
        width: 24px;
        height: 24px;
        font-size: 14px;
        line-height: 1.43;
        color: $color-grey-5;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
      }

      &.current a,
      &:hover a {
        color: $color-white;
        background-color: $color-red-1;
      }
    }
  }
}

/**Pagination*/
/**Content*/
/*Footer*/
.footer {
  &__main {
    z-index: 1;
    position: absolute;
    right: 0;
    bottom: -100px;
    left: 0;
    padding: 24px 0 16px 0;
    background-color: #fefefe;
    border-top: 1px solid #cbcbcb;

    &.contuctus {
      .footer__copyrights {
        padding: 0;
        justify-content: center;

        .footer__copyrights--text p {
          font-size: 14px;
        }
      }
    }
  }

  &__title {
    margin-bottom: 9px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;

    .icons {
      margin-right: 6px;
    }

    &:hover .i-rnd {
      box-shadow: 0 1px 9px 0 rgba(218, 25, 25, 0.29);
    }
  }

  &__links {
    @include flex_justify_align(column, space-between, start);

    .footer-contact-wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }

    &--contacts {
      li {
        &:not(:last-child) {
          margin-bottom: 8px;
        }

        a {
          font-size: 12px;
          text-decoration: none;

          .icons {
            margin-right: 8px;
          }

          &:hover .i-rnd {
            box-shadow: 0 1px 9px 0 rgba(218, 25, 25, 0.29);
          }
        }
      }
    }

    &--social {
      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    ul {
      list-style-type: none;
    }
  }

  &__copyrights {
    width: 380px;
    padding: 16px 0;
    @include flex_justify_align(row, space-around, center);

    &--logo {
      .logo:before {
        width: 132px;
        height: 40px;
      }
    }

    &--text p {
      font-size: 10px;
      text-align: center;
    }
  }

  &__maps {

    &--item {
      @include flex_justify_align(row, flex-start, start);
      flex: 1 0 50%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    &--map {
      flex: 0 0 50%;
      margin-right: 12px;
      box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.29);
    }

    &--text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 166px;
    }

    &--text {
      margin-left: 15px;
      p {
        font-size: 12px;
        max-width: 242px;
      }
    }
  }
}

/**Footer*/
/*Buttons*/
.btns {
  cursor: pointer;
  text-decoration: none;

  &:focus {
    outline: none;
  }

  &.btn-i {
    display: inline-block;
    vertical-align: middle;
  }

  &.btn-b {
    display: block;
  }

  &.btn-red_b,
  &.btn-grey_b,
  &.btn-white_b {
    padding: 12px 15px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border-radius: 21px;
  }

  &.btn-red_b {
    color: $color-white;
    background-color: $color-red-1;
    border: 1px solid $color-red-1;
    box-shadow: 0 0 16px 0 rgba(224, 69, 69, 0.16);

    &:hover {
      box-shadow: unset;
    }
  }

  &.btn-white_b {
    color: $color-red-1;
    background-color: $color-white;
    border: 1px solid $color-red-1;

    &:hover {
      color: $color-white;
      background-color: $color-red-1;
    }
  }

  &.btn-grey_b {
    color: $color-white;
    background-color: #d0d0d0;
    border: 1px solid #d0d0d0;
  }
}

/**Buttons*/
/*Icons*/
.icon {
  display: block;

  &.i64x64 {
    width: 64px;
    height: 64px;
  }
}

.icons {
  display: inline-block;
  line-height: 1;

  &:before {
    content: '';
    display: inline-block;
    background-repeat: no-repeat;
    background-position: 50%;
    vertical-align: middle;
  }

  &.i-rnd {
    border-radius: 50%;

    &:hover {
      box-shadow: 0 1px 9px 0 rgba(218, 25, 25, 0.29);
    }
  }

  &.i16x16:before {
    width: 16px;
    height: 16px;
  }

  &.i22x10:before {
    width: 22px;
    height: 10px;
  }

  &.i24x12:before {
    width: 24px;
    height: 12px;
  }

  &.i24x24:before {
    width: 24px;
    height: 24px;
  }

  &.i32x32:before {
    width: 32px;
    height: 32px;
  }

  &.i56x56:before {
    width: 56px;
    height: 56px;
  }

  &.logo:before {
    background-image: url('../img/svg/logo.svg');
    background-size: 100%;
  }

  &.i-humburger:before {
    background-image: url('../img/svg/icon-humburger.svg');
    background-size: 83.3% 62.5%;
  }

  &.i-close_red:before {
    background-image: url('../img/svg/icon-close_red.svg');
    background-size: 58.3%;
  }

  &.i-close_white:before {
    background-image: url('../img/svg/icon-close_white.svg');
    background-size: 58.3%;
  }

  &.i-play:before {
    background-image: url('../img/svg/icon-play.svg');
    background-size: 100%;
  }

  &.i-plus:before {
    background-image: url('../img/svg/icon-plus.svg');
    background-size: 83.3%;
  }

  &.i-minus:before {
    background-image: url('../img/svg/icon-minus.svg');
    background-size: 83.3% 8.33%;
  }

  &.i-arrow_right:before {
    background-image: url('../img/svg/icon-arrow_right.svg');
    background-size: 100%;
  }

  &.i-phone_rnd:before {
    background-image: url('../img/svg/icon-phone_rnd.svg');
    background-size: 100%;
  }

  &.i-email_rnd:before {
    background-image: url('../img/svg/icon-email_rnd.svg');
    background-size: 100%;
  }

  &.i-twitter_rnd:before {
    background-image: url('../img/svg/icon-twitter_rnd.svg');
    background-size: 100%;
  }

  &.i-facebook_rnd:before {
    background-image: url('../img/svg/icon-facebook_rnd.svg');
    background-size: 100%;
  }

  &.i-instagram_rnd:before {
    background-image: url('../img/svg/icon-instagram_rnd.svg');
    background-size: 100%;
  }

  &.i-geo_rnd:before {
    background-image: url('../img/svg/icon-geo_rnd.svg');
    background-size: 100%;
  }

  &.i-facebook:before {
    background-image: url('../img/svg/icon-facebook.svg');
    background-size: 41.6% 83.3%;
  }

  &.i-twitter:before {
    background-image: url('../img/svg/icon-twitter.svg');
    background-size: 87.5% 70.8%;
  }

  &.i-gmail:before {
    background-image: url('../img/svg/icon-gmail.svg');
    background-size: 83.3% 66.6%;
  }

  &.i-share:before {
    background-image: url('../img/svg/icon-share.svg');
    background-size: 75% 62.5%;
  }

  &.i-arrow-right_2:before {
    background-image: url('../img/svg/icon-arrow-right_2.svg');
    background-size: 75% 62.5%;
  }

  &.i-arrow-left_2:before {
    background-image: url('../img/svg/icon-arrow-left_2.svg');
    background-size: 75% 62.5%;
  }

  &.i-calendar:before {
    background-image: url('../img/svg/icon-calendar.svg');
    background-size: 75% 83.3%;
  }
}

/**Icons*/
/*Common*/
.hidden {
  display: none;
}

.inline {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.validation-error {
  text-align: center;
  padding-top: 10px;
  color: $color-red-1
}

.site__width:after,
.clearfix::after {
  content: "";
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
  height: 0;
  visibility: hidden;
}

/**Common*/

/*Calendar*/
.ui-datepicker {
  background-color: #fff;
  box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.29);
  overflow: hidden;
  border-radius: 4px;

  &-header {
    position: relative;
    padding: 4px;
    text-align: center;
    border-radius: 4px;
    background-color: #e04545;
  }

  &-title span {
    color: #fff;
    font-weight: 700;
  }

  &-prev,
  &-next {
    position: absolute;
    top: 4px;
    width: 20px;
    overflow: hidden;

    span {
      display: none;
      color: transparent;
    }

    &:before {
      color: #fff;
      font-weight: bold;
    }
  }

  &-prev {
    left: 4px;

    &:before {
      content: '<';
    }
  }

  &-next {
    right: 4px;

    &:before {
      content: '>';
    }
  }

  &-calendar {
    padding: 8px;

    thead th {
      padding: 4px;
      font-size: 14px;
    }

    tbody td {
      padding: 4px;
      font-size: 14px;

      .ui-state-default {
        display: block;
        margin: 0 auto;
        padding-top: 4px;
        width: 25px;
        height: 25px;
        text-align: center;
        text-decoration: none;
        border-radius: 50%;
      }

      .ui-state-default.ui-state-active {
        color: $color-white;
        background-color: $color-red-1;
      }
    }
  }
}

/**Calendar*/


/*Media*/
@media only screen and (min-width: 440px) {
  /*Content*/
  /*Pages*/
  /*Booking*/
  .booking {
    &__block {
      margin: 0 auto;
    }
  }
  /*Booking*/
  /**Pages*/
  /**Content*/
  /*Footer*/
  .footer {
    &__main {
      .site__width {
        @include flex_justify_align(row, space-between, start);
        flex-wrap: wrap;
      }

      .footer__width {
        justify-content: space-around;
      }

      &.contuctus .footer__copyrights {
        flex: 100%;
      }
    }

    &__links {
      flex: 0 0 64%;
    }

    &__copyrights {
      flex: 0 0 33%;
      flex-direction: column;

      &--logo {
        margin-bottom: 8px;
      }
    }

    &__maps {
      flex: 0 0 100%;
      padding-top: 16px;
    }
  }
  /**Footer*/
}

@media only screen and (min-width: 640px) {
  /*Content*/
  /*Sections*/
  /*Aboutus*/
  .aboutus {
    &__section {
      &--list {
        @include flex_justify_align(row, space-around, start);
      }
    }
  }
  /**Aboutus*/
  /**Sections*/
  /*Pages*/
  /**Pages*/
  /**Content*/
}

@media only screen and (min-width: 768px) {

  /*Content*/
  .page {

    &__content {
      h2 {
        line-height: 1.5;
      }
    }
  }

  .video__block {
    text-align: center;

    .btn-video {
      .mobile {
        display: none;
      }

      .desktop {
        display: inline-block;
      }
    }
  }
  /*Sections*/
  /*Aboutus*/
  .aboutus {
    &__section {
      &--list {
        justify-content: center;
      }

      &--video {
        margin: 0 15px;
      }
    }
  }
  /*Aboutus*/
  /**Sections*/
  /*Pages*/
  /*FAQs*/
  .faqs {
    &__list {
      margin: 0 auto;
      max-width: 890px;

      &--item {
        padding: 42px 24px;
      }

      &--answer p {
        font-size: 16px;
      }
    }
  }
  /**FAQs*/
  /*ContactUs*/
  .contactus {
    &__page {
      .page__content {
        margin: 0 auto;
        max-width: 890px;
      }
    }

    &__form {
      margin: 0 auto;
      max-width: 430px;
    }

    &__links {
      @include flex_justify_align(row, start, start);

      .our-info_wrapper {
        order: 1;
        align-items: center;
      }

      .contact_wrapper {
        display: flex;
        min-width: 430px;
        justify-content: space-around;
        margin-right: 40px;
      }

      .footer__copyrights {
        display: flex;
      }


      &--address {
        flex: 0 1 40%;
        min-width: 475px;
        order: 2;
      }

      &--text {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 35px;

        .direction_wrapper {
          display: flex;

          a {
            height: 32px;
            margin-right: 10px;
          }

          p {
            text-transform: uppercase;
          }
        }
      }

      &--social {
        text-align: start;
      }
    }
  }
  /**ContactUs*/
  /*Allergy*/
  .allergy {
    &Test__page,
    &Symptoms__page {
      padding-bottom: 120px;

      .page {

        &__content {
          @include flex_justify_align(row, space-between, center);
          flex-wrap: wrap;
          margin: 0 auto;
          max-width: 1010px;
        }

        &__video,
        &__image {
          flex: 0 0 430px;
        }

        &__video,
        &__image {
          &:nth-child(1) {
            margin-bottom: 64px;
          }

          &:nth-child(3) {
            margin-bottom: 0;
            order: 2;
          }
        }

        &__text {
          flex: 0 0 calc(100% - 460px);

          &:nth-child(2) {
            margin-bottom: 64px;
          }

          &:nth-child(4) {
            order: 1;
          }
        }
      }
    }

    &Test__page {
      .page__content {
        justify-content: center;
      }

      .page__text {
        min-width: 100%; // 890px;
      }
    }

    &Symptoms {
      &__page {
        .page {
          &__image {
            margin-right: 0;
            margin-left: 0;

            img {
              width: 100%;
              height: auto;
              max-width: 100%;
            }
          }
        }
      }
    }

    &Resources {
      &__list {
        @include flex_justify_align(row, space-between, strach);
        flex-wrap: wrap;

        &--item {
          margin: 0 15px 32px 15px;
          flex: 1 1 30%;

          &:hover {
            .allergyResources__list--link {
              visibility: visible;
            }
          }
        }

        &--info {
          font-size: 18px;
        }
      }
    }
  }
  /**Allergy*/
  /*Articles*/
  .articles {
    &__list {
      padding-top: 64px;

      &--item {
        @include flex_justify_align(row, space-between, center);
        margin-bottom: 42px;
      }

      &--content,
      &--image {
        flex: 1 1 49%;
      }

      &--image {
        margin: 0;
        max-width: 430px;
      }

      &--date {
        margin-bottom: 8px;
      }

      &--title {
        margin-bottom: 10px;

        a {
          font-size: 24px;
          line-height: 1.33;
        }
      }

      &--text {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    &More {
      &__sectionTitle {
        margin-bottom: 42px;
        padding-bottom: 16px;
        font-weight: 700;
      }

      &__list {
        @include flex_justify_align(row, spacebetween, start);
        margin: 0 -12px;

        &--item {
          margin: 0 12px 12px 12px;
        }

        &--image {
          margin-right: 0;
          margin-left: 0;
        }
      }
    }
  }

  .article {
    &Full__page .page__title {
      margin-bottom: 8px;
    }

    &__content {
      padding-bottom: 64px;

      &--date {
        margin-bottom: 40px;
      }

      &--image {
        margin: 0 0 24px 0;
      }
    }
  }
  /**Articles*/
  /*Booking*/
  .booking {
    &__selectAge {
      margin-bottom: 48px;

      &--note {
        font-size: 14px;
        line-height: 1;
      }
    }

    &__calendar {
      &--month {
        font-size: 18px;
      }

      &--day {

        &.closed,
        &.open,
        &.selected {
          padding: 12px;

          span {
            padding-top: 13px;
            width: 52px;
            height: 52px;
            font-size: 26px;
          }
        }
      }
    }

    &__time {

      &--block {
        padding: 26px;
      }

      &--item {
        .btns.btn-i {
          margin-top: -20px;
          padding: 12px 16px;
          width: 170px;
          font-size: 14px;
        }
      }

      &--title {
        margin-bottom: 32px;
        font-size: 18px;
      }

      &--time,
      &--price {
        font-size: 18px;
      }
    }

    &__form,
    &__confirm {
      &--cencel {
        font-size: 18px;
      }

      &Header {
        &--time {
          font-size: 18px;

          span {
            font-size: 18px;
            font-weight: 600;
          }
        }

        &--price {
          font-size: 24px;
        }
      }
    }

    &__form--bg {
      padding-left: 42px;
      padding-right: 42px;
    }
  }
  /**Booking*/
  /**Pages*/
  /**Content*/

  /*Footer*/
  .footer {
    &__links {
      align-items: center;
      flex-direction: row;
    }
  }
  /**Footer*/
}

@media only screen and (min-width: 993px) {
  /*Basic*/
  body {
    font-size: 18px;
  }

  .desktop {
    display: block;
  }

  img.desktop {
    display: inline-block;
  }

  .mobile {
    display: none;
  }

  .site__width {
    padding: 0 26px;
  }
  /**Basic*/

  /*Content*/
  .section,
  .page {
    &__title {
      font-size: 32px;

      &:after {
        width: 100px;
      }
    }

    &__subtitle {
      margin-bottom: 32px;

      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }


  /*Header*/
  .header {
    &__main {
      padding: 14px 0;
    }

    &__logo {
      margin-right: 25px;

      .logo:before {
        width: 160px;
        height: 50px;
      }
    }

    &__humburger {
      display: none;
    }

    &__nav {
      float: right;
      padding-top: 0;
      width: calc(100% - 185px);
    }

    &__menu {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      display: block;
      padding: 0;
      width: auto;
      background-color: transparent;
      overflow: unset;

      &--list {
        float: left;
        padding-top: 14px;

        & > li {
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: top;

          &:not(:last-child) {
            margin-right: 3px;
          }

          & > span,
          & > a {
            font-size: 12px;

            & + ul {
              display: none;
              padding: 8px 16px;
              border-radius: 5px;
              box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.04);
              background-color: $color-white;

              & > li {
                text-align: left;

                &:not(:last-child) {
                  margin-bottom: 6px;
                }

                & > a {
                  white-space: nowrap;
                }
              }
            }

            & + ul:hover,
            &:hover + ul {
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
            }
          }
        }
      }

      &--btns {
        float: right;
        padding-top: 4px;

        .btns {
          display: inline-block;
          float: right;
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }
    /**Header*/
    /*Buttons*/
    .btns {
      font-size: 16px;
    }
  }

  .page {
    padding-bottom: 64px;

    &__subtitle {
      margin: 0 auto 32px auto;
      max-width: 850px;
      font-size: 16px;
    }

    &__content {
      h2 {
        font-size: 32px;
      }
    }

    &__video {
      margin-bottom: 32px;
    }

    &__text p {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .video__block {
    .btn-video {
      max-width: 890px;
    }
  }
  /*Sections*/
  /*Hero*/
  .hero {

    &__section {
      padding-bottom: 50px;
      background-image: url('../img/hero_bg.png');
      background-image: -webkit-image-set(url('../img/hero_bg.png') 1x, url('../img/hero_bg@2x.png') 2x, url('../img/hero_bg@3x.png') 3x);
      background-position: 0 100%;
      background-repeat: no-repeat;

      .site__width {
        @include flex_justify_align(row, space-between, center);
      }
    }

    &__image {
      flex: 1 1 50%;
      order: 2;
      max-width: 648px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__info {
      flex: 1 1 50%;
      order: 1;
      max-width: 545px;

      &--title {
        font-size: 32px;
      }

      &--logo {
        .logo:before {
          width: 452px;
          height: 139px;
        }
      }

      &--text {
        max-width: unset;
      }
    }
  }
  /**Hero*/
  /*Aboutus*/
  .aboutus {
    &__section {
      padding: 25px 0;

      &--link {
        font-size: 14px;
      }
    }
  }
  /**Aboutus*/
  /*Appointment*/
  .appointment {
    &__section {
      padding-bottom: 130px;

      &--title {
        max-width: 630px;
        font-size: 32px;
      }
    }
  }
  /*Appointment*/
  /**Sections*/
  /*Pages*/
  /*FormsListLinks*/
  .formsListLinks {
    &__list {
      margin-top: 50px;

      &--item {
        // margin: 80px 0;
      }
    }
  }
  /**FormsListLinks*/
  /*Allergy*/
  .allergy {
    &Test {
      &__page {
        padding-bottom: 130px;

        .page {
          &__subtitle {
            margin-bottom: 80px;
          }
        }
      }
    }

    &Types {
      &__page {
        padding-bottom: 80px;
      }

      &__info {
        h2 {
          margin-bottom: 32px;
          font-size: 32px;
        }

        p {
          margin-bottom: 20px;
        }

        ul li {
          margin-bottom: 16px;
          font-size: 16px;

          &:before {
            top: 12px;
          }
        }
      }
    }

    &Resources {
      &__list {
        margin: 0 auto;
        max-width: 1010px;
      }
    }
  }
  /**Allergy*/
  /*ContactUs*/
  .contactus {

    &__map,
    &__form {
      margin: 0;
      float: right;
      width: 49%;
      max-width: 430px;
    }

    &__form {
      float: left;
    }

    &__links {
      clear: both;
      padding-top: 25px;
      // min-width: 1200px;
      padding-left: -200px;
    }
  }
  /**ContactUs*/
  /*AboutUs*/
  .aboutus {
    &__page {
      background-image: url('../img/hero_bg.png');
      background-image: -webkit-image-set(url('../img/hero_bg.png') 1x, url('../img/hero_bg@2x.png') 2x, url('../img/hero_bg@3x.png') 3x);
      background-position: 100% 10%;
      background-repeat: no-repeat;

      .page__image {
        float: right;
        margin-bottom: 0;
        width: 60%;
        max-width: 800px;
      }

      .page__content {
        float: left;
        width: 38%;
        max-width: 455px;

        h2 {
          padding-top: 64px;
        }

        ul {
          padding-bottom: 20px;
        }

        .small__text {
          font-size: 18px;
          line-height: 1.5;
        }
      }
    }
  }
  /*AboutUs*/
  /*Advisors*/
  .advisors,
  .team {
    &__page {
      background-image: url('../img/hero_bg.png'), url('../img/hero_bg.png');
      background-position: 0 100px, 100% 90%;
      background-repeat: no-repeat;
    }

    &__list {
      @include flex_justify_align(row, center, start);
      flex-wrap: wrap;

      &--item {
        margin: 5px 15px;
        flex: 0 0 315px;

        &:hover {
          .advisors__list--link,
          .team__list--link {
            visibility: visible;
          }
        }
      }
    }
  }
  /**Advisors*/
  /*Team*/
  .team {
    &__page {
      background-position: 0 100px, 100% 50%;
    }
  }
  /**Team*/
  /*Staff*/
  .staff {
    &__page {
      background-image: url('../img/hero_bg.png'), url('../img/hero_bg.png');
      background-position: 0 100px, 100% 90%;
      background-repeat: no-repeat;
    }

    &__link {
      padding-bottom: 0;
    }

    &__title {
      margin-bottom: 4px;
      font-size: 32px;
      line-height: 1;
    }

    &__subtitle {
      margin-bottom: 32px;
      font-size: 14px;
    }

    &__content {
      padding-bottom: 0;

      p {
        margin-bottom: 32px;
      }
    }
  }
  /**Staff*/
  /*FormsListLinks*/
  .formsListLinks {
    &__page {
      padding-bottom: 150px;

      &:before {
        width: 625px;
        height: 552px;
        background-image: url('../img/forms_listlinks_bg_left.jpg');
        background-image: -webkit-image-set(url('../img/forms_listlinks_bg_left.jpg') 1x, url('../img/forms_listlinks_bg_left@2x.jpg') 2x, url('../img/forms_listlinks_bg_left@3x.jpg') 3x);
      }

      &:after {
        width: 564px;
        height: 523px;
        background-image: url('../img/forms_listlinks_bg_right.jpg');
        background-image: -webkit-image-set(url('../img/forms_listlinks_bg_right.jpg') 1x, url('../img/forms_listlinks_bg_right@2x.jpg') 2x, url('../img/forms_listlinks_bg_right@3x.jpg') 3x);
      }
    }

    &__list {
      &--item {
        margin-bottom: 32px;
      }
    }
  }
  /**FormsListLinks*/
  /*Articles*/
  .articles {
    &__list {
      margin: 0 auto;
      max-width: 900px;
    }
  }
  /**Articles*/
  /**Pages*/
  /**Content*/
  /*Footer*/
  .footer {

    &__title {
      font-size: 14px;
    }

    &__maps {
      flex: 0 0 50%;
      order: 1;
      padding-top: 0;

      &--text p {
        font-size: 14px;
      }
    }

    &__links {
      flex: 0 0 20%;
      flex-direction: column;
      order: 2;

      &--contacts {
        margin-bottom: 18px;

        li a {
          font-size: 14px;
        }
      }
    }

    &__copyrights {
      flex: 0 0 25%;
      order: 3;

      &--logo .logo:before {
        width: 200px;
        height: 60px;
      }

      &--text p {
        font-size: 14px;
      }
    }
  }
  /**Footer*/
}

@media only screen and (min-width: 1200px) {
  /*Basic*/
  .site__width {
    padding: 0 45px;
  }
  /**Basic*/
  /*Content*/
  /*Pages*/
  /*Articles*/
  .articles {
    &__list {
    }
  }
  /**Articles*/
  /**Pages*/
  /**Content*/
}

@media only screen and (min-width: 1280px) {
  /*Header*/
  .header {
    &__main {
      padding: 14px 0;
    }

    &__logo {
      margin-right: 25px;

      .logo:before {
        width: 160px;
        height: 50px;
      }
    }

    &__humburger {
      display: none;
    }

    &__nav {
      float: right;
      padding-top: 0;
      width: calc(100% - 185px);
    }

    &__menu {
      position: relative;
      top: unset;
      right: unset;
      bottom: unset;
      display: block;
      padding: 0;
      width: auto;
      background-color: transparent;
      overflow: unset;

      &--list {
        float: left;
        padding-top: 14px;

        & > li {
          position: relative;
          display: inline-block;
          margin-bottom: 0;
          vertical-align: top;

          &:not(:last-child) {
            margin-right: 24px;
          }

          & > span,
          & > a {
            font-size: 14px;

            & + ul {
              display: none;
              padding: 8px 16px;
              border-radius: 5px;
              box-shadow: 0 4px 10px 3px rgba(0, 0, 0, 0.04);
              background-color: $color-white;

              & > li {
                text-align: left;

                &:not(:last-child) {
                  margin-bottom: 6px;
                }

                & > a {
                  white-space: nowrap;
                }
              }
            }

            & + ul:hover,
            &:hover + ul {
              display: block;
              position: absolute;
              top: 100%;
              left: 0;
            }
          }
        }
      }

      &--btns {
        float: right;
        padding-top: 4px;

        .btns {
          display: inline-block;
          float: right;
          margin-left: 12px;
          font-size: 14px;
        }
      }
    }
  }
  /**Header*/
  /*Buttons*/
  .btns {
    font-size: 16px;
  }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.delete-attachment {
  padding-left: 10px;
  cursor: pointer;
}

.booking__formHeader--title {
  display: block;
}
.booking__confirmHeader--location-info {
  & h5 {
    font-size: 20px;
    margin-bottom: 7px;
  }
  & p {
    font-size: 16px;
  }
}

.header__menu--item > a {
  text-transform: none;
}

.notifications-tr {
  width: 340px !important;
}
.plus-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    display: inline-block;
    background-color: #e04545;
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 3px;
    display: inline-block;
    background-color: #e04545;
    transform: translateX(-50%);
  }
}

.adduser_btn:hover {
  .plus-icon {
    &:before, &:after {
      background-color: #fff;
    }
  }
}

.text-editor-wrapper {
  height: 100%;
}
.text-editor {
  height: 93%;
}

.article-cover-image {
  max-width: 100%;
  max-height: 100%;
  margin-bottom: 20px;
}


.create-article-title-row {
  margin-top: 25px;
}

.content-validation-error {
  padding-top: 0;
}

.uppercase {
  text-transform: uppercase !important;
}
.transform_none {
  text-transform: none !important;
}

.article-attachment {
  font-weight: 600;
  margin-bottom: 30px;
}
.article-video {
  margin: 10px 0;
}

 // text editor links
.rdw-link-decorator-wrapper a {
  color: blue;
}

iframe{
  overflow:hidden;
}

.blog-table .rdt_TableCol {
  margin-right: 110px;
}

 // pdf viewer
#root > div:nth-child(1) > div > div > div > article > div:nth-child(4) > div > div:nth-child(2) > div > div:nth-child(1) {
  height: auto !important;
}

// Loader animation fixing
.button.is-loading::after, .loader, .select.is-loading::after, .control.is-loading::after {
  animation: none;
  border: none;
}

.original-link-decor {
  text-decoration: underline;
  color: #e04545;
  font-size: 16px;
  font-weight: 600;
}

.bold {
  font-weight: 800 !important;

}

.PaymentForm_form {
  padding: 0 20px;
  text-align: center;
  .btns.btn-red_b {
    margin: 0 auto;
  }
}

.card_element {
  padding: 16px;
  border: 1px solid rgba(13, 13, 13, 0.64);
  border-radius: 5px;
  margin-bottom: 15px;
}

.isSuccess {
  text-align: center;
  padding: 16px 14px 14px 14px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: #e04545;
}
/**Media*/