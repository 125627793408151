.-wrapper {
    background-color: #f9f9f9;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    width: auto;
}

.availableList-container {
    background-color: #fff;
    border: 10px solid #f9f9f9;
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5% 0;
}

.availableList-list {
    list-style-type: none;
    width: 97%;
    margin: 0;
}

.availableList-list li {
    border-bottom: solid 1px rgba(151, 151, 151, 0.23);
}

.availableList-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    padding: 24px 0;
}

.disabled-availableList-item {
    opacity: 0.5;
    cursor: not-allowed;
}

.availableList-item:last-child {
    border-bottom: none;
}

.availableList-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 13px;
}

.availableList-title {
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 20px;
    letter-spacing: normal;
    text-align: center;
    color: #324258;
}

.availableList-title p {
    font-weight: inherit;
}

.availableList-title button {
    font-size: 18px;
    font-weight: 700;
    color: #e04545
}

.availableList-description {
    padding: 15px;
    text-transform: none;
    font-family: OpenSans, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 28px;
    letter-spacing: normal;
    text-align: center;
    color: #324258;
    width: 315px;
}


.availability-error {
    padding-top: 0 !important;
    padding-bottom: 15px;
}

.availableList-item-time {
    font-family: OpenSans, sans-serif;
    font-weight: 700;
    font-size: 18px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2b2b2b;
    width: 110px;
}

.availableList-item-time p {
    font-family: 'Roboto-Medium', sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    opacity: 0.34;
    margin-bottom: 5px;
}

.delete-svg {
    cursor: pointer;
    padding-top: 20px;
}

.add-available-wrapper {
    width: 100%;
    text-align: start;
    display: flex;
    justify-content: start;
    align-items: center;
    height: 24px;
    margin: 25px 0;
    padding: 0 25px;
}

.add-available-button {
    text-transform: none;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.43;
    color: #7f8fa4;
    margin-left: 8px;
}

.controls-wrapper {
    margin-top: 30px;
}

.cancel-button {
    color: #4da1ff;
    margin-left: 24px !important;
}


@media (max-width: 576px) {
    .availableList-item-time {
        margin: 0 5px;
    }

    .availableList-title {
        margin-bottom: 10px;
    }

    .add-available-wrapper {
        padding: 0;
        margin-top: 25px;
        margin-left: 35px;
        font-size: 12px;
    }

    .controls-wrapper button {
        padding: 10px 30px !important;
    }
}

.drop-down {
    width: 50%;
}

.drop-down .drop-down-list {
    max-height: 300px;
}

.availability-custom-select {
    width: 186px;
    margin-top: 18px;
    z-index: 5;
}

@media (min-width: 1200px) {
    .drop-down {
        width: 50%;
    }
}
